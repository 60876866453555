import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import { INTERNAL_COUNTRY_CODES } from "../../../constants/address";
import {
  ReferenceActions,
  ReferenceSelectors,
} from "../../../redux/references";
import { checkIfPostcodeValid } from "../../../models/postcode";
import {
  clearAlternativeDeliveryDates,
  updateFormAddressFields,
  clearDateAndServiceFields,
  getAlternativeDatesAction,
  resetAddressAction,
} from "../actions";

export const withAddressEdit = compose(
  withAppLoader({
    loadFn: ({ dispatch }) => dispatch(ReferenceActions.fetchCountries()),
  }),
  connect(
    state => ({ countries: ReferenceSelectors.getCountries(state) }),
    (dispatch, { match, parcel, countries }) => ({
      onAddressSelect: address => {
        dispatch(clearDateAndServiceFields());
        dispatch(updateFormAddressFields(address));
        dispatch(
          getAlternativeDatesAction({
            address,
            actionCode: match.params.actionCode,
            parcel,
          })
        );
      },
      onPostcodeFieldBlur: (postcode, countries) => {
        const countryCode = parcel.deliveryDetails.address.countryCode;
        const isPostcodeValid = checkIfPostcodeValid({
          postcode,
          countryCode,
          countries,
        });

        if (!postcode) {
          dispatch(clearDateAndServiceFields());
          dispatch(resetAddressAction());
          dispatch(clearAlternativeDeliveryDates());
        }

        if (isPostcodeValid) {
          dispatch(clearDateAndServiceFields());
          dispatch(
            getAlternativeDatesAction({
              address: { postcode },
              actionCode: match.params.actionCode,
              parcel,
            })
          );
        }
      },
    })
  ),
  withProps(({ additionalInitialValues, parcel, countries }) => {
    const countryCode = parcel.deliveryDetails.address.countryCode;
    const isCountryCodeUK = countryCode === INTERNAL_COUNTRY_CODES.UK;

    return {
      displayPostcodeFinder: isCountryCodeUK,
      // [CMP-457] set postcode required for alternative address submit request
      isPostcodeRequired: true,
    };
  })
);
